import React from "react"
import { Link } from "gatsby"
import Contacts from "../components/contacts"
import Seo from "../components/seo"
import Container from "../components/shared/policies/termsContainer"
import Text from "../components/shared/policies/textContainer"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Paragraphs from "../components/shared/policies/paragraphs"
import NewFooter from "../components/shared/NewFooter"

function Licensing() {
  return (
    <>
      <Container>
        <Seo title="Refund Policy – PDF Pro Software Inc." />
        <Title>Refund Policy – PDF Pro Software Inc.</Title>
        <Text>
          <Paragraphs>
            <i>Last Updated: May 16th, 2022</i>
          </Paragraphs>
          <Paragraphs>
            Here is PDF Pro Software Inc.'s policy for requesting a refund for
            your software
          </Paragraphs>
          <ul>
            <li>How long do I have to request a refund?</li>
          </ul>
          <Paragraphs>
            We will honour any refund request made within 30 days of purchase
          </Paragraphs>
          <ul>
            <li>Are there any conditions for requesting a refund?</li>
          </ul>
          <Paragraphs>
            No, you may request a refund for any reason. We are always looking
            to improve our products and would appreciate that you leave us any
            feedback that you have for us.
          </Paragraphs>
          <ul>
            <li>How long do I have to request a refund?</li>
          </ul>
          <Paragraphs>
            Please contact customer support and submit a support ticket. Please
            be sure to include your order number. Support can be reached at the
            following url:{" "}
            <Link to="https://support.pdfpro.com/hc/en-us/requests/new">
              https://support.pdfpro.com/hc/en-us/requests/new
            </Link>
          </Paragraphs>
          <ul>
            <li>How long will it take to process my refund?</li>
          </ul>
          <Paragraphs>
            We typically need 1-2 business days to begin processing the refund
            depending on the volume of customer support requests we have in our
            backlog. Afterwards, the refund should appear on your credit card in
            2-3 business days.
          </Paragraphs>
          <ul>
            <li>Are the any costs associated with refunds?</li>
          </ul>
          <Paragraphs>
            Nope! You will receive the full purchase price back.
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter />
    </>
  )
}

export default Licensing
